import React from "react";
import { Link } from "gatsby";
import Layout from '../components/layout-default';

import "../styles/mindbody.scss";

const Helpfull = () => {
    return (
        <Layout
            title="Best Studio Rentals in Orange County | RF Dance"
            description="Discover the best studio rentals in Orange County and unlock a world of possibilities for your next project. Book now and start turning your vision into reality."
            pathname="account"
            noHero="no-hero">
            <div className="container">
                <div className="btn_container" style={{ marginTop: '140px', marginBottom: '50px' }}>
                    <Link className="teamBtn_links" to='https://1drv.ms/w/s!Akim7jefzOmrjSwlKC9pIqgU1JVc' target="_blank">Shoes, Knee pads & Socks</Link>
                    <Link className="teamBtn_links" to='https://1drv.ms/w/s!Akim7jefzOmriw0xpnoAuBe9Zfmr' target="_blank">Accessories</Link>
                    <Link className="teamBtn_links" to="https://1drv.ms/w/s!Akim7jefzOmriEq6w_s957Hpm8xI" target="_blank">Make up</Link>
                    <Link className="teamBtn_links" to="" target="_blank">Apparel</Link>
                   
                </div>
            </div>
        </Layout>
    )
}

export default Helpfull;